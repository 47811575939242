import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

class Layout extends Component {
  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: "description",
                  content:
                    "The Place where Game meets Reality. Das neuartige Freizeitangebot im Raum SH/ZH-Wyland, das Augmented Reality und Kartfahren in verschiedenen Games kombiniert",
                },
                {
                  name: "keywords",
                  content:
                    "go-karts, kartgames, fun, action, family-games, familienspiel, gruppen, events",
                },
                { name: "charset", content: "utf-8" },
                { name: "http-equiv", content: "IE=edge" },
                { name: "author", content: "intesso engineering" },
                {
                  name: "viewport",
                  content: "width=device-width, initial-scale=1",
                },
              ]}
            >
              <html lang="de" />
            </Helmet>
            <div className={"page-top"}>{children}</div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
